<script setup>
import {onMounted} from 'vue';
import lottie from 'lottie-web'

const props = defineProps({
  data: {
    type: Object,
    default: {},
  },
  size: {
    type: String,
    default: '120px'
  },
});

const elm = ref();

onMounted(() => {
  lottie.loadAnimation({
    container: elm.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    // path: props.src // the path to the animation json
    animationData: props.data,
  })

})


</script>

<template>
  <div
      ref="elm"
      :style="{width:size, height:size}"
  ></div>
</template>

<style lang="scss" scoped>

</style>
